<template>
  <div class="register-step2">
    <top-bar back backroute="auth" is-auth :img="logo" />
    <div class="container">
      <div class="title"><t name="3.21" /></div>
      <div class="wrap">
        <n-input type="phone" :title="$app.secure.clean($n.t('3.22'))" v-bind="$form.input('bin')"></n-input>
        <n-input type="phone" :title="$app.secure.clean($n.t('3.23'))" v-bind="$form.input('address')"></n-input>
        <n-input type="phone" :title="$app.secure.clean($n.t('3.24'))" v-bind="$form.input('name')"></n-input>
        <n-button class="button-next" color="yellow" wide @click="next"><t name="3.13" /></n-button>
      </div>
    </div>
  </div>
</template>

<script>
import logo from 'assets/little-logo.png'
import img from 'assets/phone.png'

export default {
  name: 'PageFinishRegister',
  data() {
    return {
      logo, img,
      categories: [],
      subcategories: [],
      category: {},
      subcategory: {},
    }
  },
  watch: {
    category() {
      this.loadSubCategories()
    },
  },
  created() {
    this.$form.init({
      bin: '',
      address: '',
      name: '',
    })
    this.$form.rules({
      bin: [ 'required', ],
      address: [ 'required', ],
      name: [ 'required', ],
    })
    this.loadCategories()
  },
  methods: {
    next() {
      if (this.$form.check()) {
        const data = $n.getLocalVal('regData')
        data.name = this.$form.get('name')
        data.address = this.$form.get('address')
        data.bin = this.$form.get('bin')
        this.$var('load', true)
        $api.auth.register(data).then((response) => {
          this.$router.push({ name: 'auth', })
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    loadCategories() {
      this.$var('load', true)
      $api.categories.get().with('children').all().then((response) => {
        this.categories = $n.map(response.data.content, (e) => {
          return {
            value: e.id,
            title: e.name,
          }
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadSubCategories() {
      this.$var('load', true)
      $api.categories.get(this.category.value).with('children').all().then((response) => {
        this.subcategories = $n.map(response.data.content.children, (e) => {
          return {
            value: e.id,
            title: e.name,
          }
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.register-step2 {
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    margin: 0 auto 8px;
  }
  .button-next {
    margin-top: 25px;
  }
  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    .category {
      margin-bottom: 10px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      .radio {
        margin-right: 15px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 2px solid #D9D9D9;
        display: flex;
        align-items: center;
        justify-content: center;
        .active {
          width: 10px;
          height: 10px;
          background-color: var(--primary);
          border-radius: 50%;
        }
        &.active {
          border: 2px solid var(--primary);
          position: relative;
        }
      }
    }
    .title {
      text-align: left;
      font-weight: 400;
      margin-bottom: 0;
      font-size: 16px;
      color: #8C8C8C;
    }
  }

  .title {
    margin-top: 70px;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 24px;
  }

  .content {
    font-size: 16px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 24px;
  }

  .n-input {
    margin-bottom: 16px;
  }

  .n-button {
    position: relative;

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>
